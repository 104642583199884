<template>
  <div class="card">
    <div class="header">
      <div class="img"></div>
      <!-- <div class="details">
        <span class="name"></span>
        <span class="about"></span>
      </div> -->
    </div>
    <div class="description">
      <div class="line line-1"></div>
      <div class="line line-3"></div>
      <div class="line line-1"></div>
      <!-- <div class="line line-2"></div> -->
    </div>
    <!-- <div class="btns">
      <div class="btn btn-1"></div>
      <div class="btn btn-2"></div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SkeletonLoading'
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.card{
  max-width: 350px;
  width: 100%;
  background: #fff;
  padding: 30px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}
.card .header{
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .img{
  height: 60px;
  width: 60px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.header .details{
  margin-left: 20px;
}
.details span{
  display: block;
  background: #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.details .name{
  height: 15px;
  width: 100px;
}
.details .about{
  height: 13px;
  width: 150px;
  margin-top: 10px;
}
.card .description{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}
.description .line{
  background: #d9d9d9;
  border-radius: 10px;
  height: 13px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;
}
.description .line-1{
  width: calc(100% - 15%);
}
.description .line-3{
  width: calc(100% - 40%);
}
.card .btns{
  display: flex;
}
.card .btns .btn{
  height: 45px;
  width: 100%;
  background: #d9d9d9;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
}
.btns .btn-1{
  margin-right: 8px;
}
.btns .btn-2{
  margin-left: 8px;
}
.header .img::before,
.details span::before,
.description .line::before,
.btns .btn::before{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #d9d9d9 0%, rgba(0,0,0,0.05) 20%, #d9d9d9 40%, #d9d9d9 100%);
  background-repeat: no-repeat;
  background-size: 450px 400px;
  animation: shimmer 1s linear infinite;
}
.header .img::before{
  background-size: 650px 600px;
}
.details span::before{
  animation-delay: 0.2s;
}
.btns .btn-2::before{
  animation-delay: 0.22s;
}
@keyframes shimmer {
  0%{
    background-position: -450px 0;
  }
  100%{
    background-position: 450px 0;
  }
}
</style>