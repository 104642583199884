<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row class="">
      <v-col cols="12" md="7" class="text-left px-3 mt-1">
        <v-row>
          <div class="text-left black--text pt-7">
            <div class="text-left px-3 black--text">
              {{ $t("candidancy") }}
            </div>
          </div>
          <div class="black--text pt-7">
            <div class="black--text px-0">
              <v-icon color="black">mdi-chevron-right</v-icon>
            </div>
          </div>

          <div class="text-left black--text pt-7">
            <div class="text-left px-3 primary--text">
              {{ $t("spontaneous_applications") }}
            </div>
          </div>
          <!-- <a
              class="text-left black--text px-0 pt-7"
              @click="$emit('viewExpo')"
            >
              {{ $t("spontaneou_application") }}
            </a> -->
        </v-row>
      </v-col>

      <v-col cols="12" md="5" class="text-right px-6 mt-2">
        <v-row>
          <div class="px-0 pt-3 px-2">
            <candidate-filter
              v-if="vacancyId"
              :database="true"
              :vacancies="vacancies"
              :vacancyId="vacancyId"
              @apply="applyCandidateFilter"
              @change="setVacancy"
              @clean="applyCandidateFilter"
            />
          </div>
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('searchCandidate')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row class="my-3">
      <v-col cols="12" md="6">
        <v-row class="align-center">
          <v-radio-group
            v-model="filter.jobType"
            row
            hide-details
            color="primary"
            class="px-2 my-3"
          >
            <v-radio
              @click="applyFilter()"
              :label="$t('job')"
              :value="'job'"
              hide-details
            ></v-radio>
            <v-radio
              :label="$t('Internship')"
              :value="'Internship'"
              hide-details
              @click="applyFilter()"
            ></v-radio>
          </v-radio-group>
          <v-row class="mx-2">
            <v-badge color="primary" :content="Length" overlap v-if="Length > 0"
              ><v-icon>mdi-folder-open</v-icon></v-badge
            >
            <v-icon v-else>mdi-folder-open</v-icon>

           <div class="mt-1">
              <v-btn text x-small color="primary" @click="gotoCandidancy()"
                ><span class="black--text">{{ $t("candidancy") }}</span></v-btn
              >
            </div>
          </v-row>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="accessLevel(currentUser, 'send_test')"
                :disabled="!isSelected"
                text
                @click="dialogTest = true"
                color="primary"
              >
                <v-icon left color="primary">mdi-send</v-icon
                >{{ $t("send_test") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_sendtest_candidate')"></div>
          </v-tooltip>
        </template>

        <template>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="accessLevel(currentUser, 'schedule_interview')"
                :disabled="!isSelected"
                color="primary"
                @click="dialogInterview = true"
                style="margin-left: 5px"
              >
                <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_interview')"></div>
          </v-tooltip>
        </template>
        <v-btn
          v-if="accessLevel(currentUser, 'schedule_interview')"
          :disabled="!isSelected"
          color="primary"
          @click="dialogInterview = true"
          style="margin-left: 5px; display: none"
        >
          <v-icon left>mdi-calendar</v-icon>{{ $t("schedule_interview") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="px-0">
      <!-- <pre>
        {{filter}}
      </pre> -->
      <template v-if="!loadingData">
        <v-col
          v-for="candidate in filteredItems"
          cols="12"
          md="3"
          xl="3"
          :key="candidate.id"
        >
          <candidate-card
            :candidate="candidate"
            :filter="filter"
            :from="'candidacies/unsolicited'"
            :selectAll="selectAll"
            @checked="checkedCandidate"
            :vacancyId="vacancyId"
            :match="`0.00`"
          />
        </v-col>
      </template>
      <template v-for="i in 12">
        <v-col v-if="loadingData" cols="12" md="3" xl="3" :key="i">
          <skeleton-loading />
        </v-col>
      </template>
    </v-row>

    <div class="d-flex justify-center">
      <div justify="center" v-show="filteredItems.length < 1">
        <div class="grey--text" v-html="$t('tooltip_candidate')"></div>
      </div>
    </div>

    <info-view-dialog
      :dialog="infoDialog"
      :data="infoView"
      @close="infoDialog = false"
    />
    <schedule-interview-dialog
      :dialog="dialogInterview"
      :selectedCandidates="selectedCandidates"
      :vacancyId="vacancyId"
      @close="dialogInterview = false"
    />
    <select-test
      :selectedCandidates="selectedCandidates"
      :dialog="dialogTest"
      :vacancyId="null"
      :vacancies="vacancies"
      @close="dialogTest = false"
      @done="dialogTest = false"
    />
    <div id="sensor"></div>
  </div>
</template>

<script>
import { GET_PUBLISHED_VACANCIES_QUERY } from "../graphql/Query.resolver";
import {
  GET_TOTAL_CANDIDATE,
  GET_UNSOLICITED_CANDIDATES_ENTITY_QUERY,
} from "../../candidate/graphql/Query";
import { CANDIDATE_CANDIDACY_SUBSCRIPTION } from "../graphql/Subscraption.resolver";
import scrollMonitor from "scrollmonitor";
import { mapActions, mapGetters } from "vuex";
import CandidateCard from "../../../components/CandidateCard.vue";
import CandidateFilter from "../../../components/CandidateFilter.vue";
import InfoViewDialog from "../../../components/InfoViewDialog.vue";
// import Pagination from '../../../components/Pagination.vue'
import ScheduleInterviewDialog from "./../components/ScheduleInterviewDialog";
import SelectTest from "../components/SelectTest.vue";
import SkeletonLoading from "../../../components/SkeletonLoading.vue";
// import InfiniteScroll from "../../../components/InfiniteScroll.vue";
import accessLevelMixins from "@/mixins/access-level";
import matchCandidate from "@/mixins/matchCandidate";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import yearsMixins from "@/mixins/years";
export default {
  name: "Unsolicited",
  mixins: [
    accessLevelMixins,
    replaceSpecialCharsMixins,
    yearsMixins,
    matchCandidate,
  ],
  components: {
    CandidateCard,
    CandidateFilter,
    InfoViewDialog,
    // Pagination,
    ScheduleInterviewDialog,
    SelectTest,
    SkeletonLoading,
    // InfiniteScroll,
  },
  data: () => ({
    candidates: [],
    candidacies: [],
    appliedFilter: false,
    blockeds: [],
    dialogInterview: false,

    dialogTest: false,
    filter: {
      jobType: "job",
      areasInterest: undefined,
      province: undefined,
      otherSkills: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      limit: 50,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
      vacancyId: undefined,
    },
    infoDialog: false,
    loadingData: true,
    skipQuery: true,
    selectAll: false,
    selectedCandidates: [],
    totalCandidates: null,
    textSearch: "",
    // totalPages: 1,
    vacancyId: null,
    vacancies: [],
    listCandidade: false,
    Length: 0,
  }),
  apollo: {
    vacancies: {
      query: GET_PUBLISHED_VACANCIES_QUERY,
      fetchPolicy: "no-cache",
    },
    Length: {
      query: GET_TOTAL_CANDIDATE,
      fetchPolicy: "no-cache",
    },
    candidates: {
      query: GET_UNSOLICITED_CANDIDATES_ENTITY_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return { filterCandidate: this.filter };
      },
      skip() {
        return this.skipQuery;
      },
      result({ loading }) {
        if (!loading) {
          this.loadingData = false;
        }
      },
    },
    $subscribe: {
      candidateCandidacy: {
        query: CANDIDATE_CANDIDACY_SUBSCRIPTION,
        result({ data }) {
          this.candidates.candidates.push(
            data.candidateCandidacy.candidacy.candidate
          );
        },
      },
    },
  },
  watch: {
    vacancies: function (val) {
      if (!this.vacancyId && val.length > 0) {
        this.vacancyId = val.length > 0 ? val[0].id : undefined;
        this.changeVacancy();
      } else if (val.length > 0) {
        this.changeVacancy();
      }
    },
    vacancyId(val) {
      if (val) {
        this.applyFilter();
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      modules: "auth/getModules",
    }),
   filteredItems() {
      if (this.vacancies.length > 0) {
        let resultItems = this.candidates.candidates
          ? this.candidates.candidates.map((candidate) => {
              const findIndex = this.candidates.blockeds.findIndex(
                (blockedId) => blockedId == candidate.id
              );
              if (findIndex > -1) {
                return { ...candidate, blocked: true };
              }
              return { ...candidate, blocked: false };
            })
          : [];
        if (this.textSearch) {
          const text = this.replaceSpecialChars(this.textSearch);
          resultItems = resultItems.filter(
            (t) =>
              this.replaceSpecialChars(t.name+" "+t.lastName).includes(text) ||
              (t.profession &&
                this.replaceSpecialChars(t.profession).includes(text))
          );
        }
        return resultItems;
      } else {
        return [];
      }
    },
    isSelected() {
      return this.selectedCandidates.length > 0;
    },
    lists() {
      // return []
      return this.vacancies && this.vacancies.length
        ? this.vacancies.find((v) => v.id == this.vacancyId).favorites
        : [];
    },
    lastModules() {
      return this.modules.length - 1;
    },
    infoView() {
      return {
        icon: "mdi-account-group",
        title: this.$t("candidancy"),
        content: this.$t("candidancy_info"),
      };
    },
  },
  created() {
    if (this.$route.query.vacancyId) {
      this.vacancyId = this.$route.query.vacancyId;
      // this.changeVacancy()
    }
    if (
      this.$route.query.filter &&
      this.verifyFilter(this.$route.query.filter)
    ) {
      console.log("------------YES-------------");
      let urlFilter = this.$route.query.filter;
      urlFilter.limit = 50;
      this.filter = urlFilter;
      this.applyFilter();
    }
    // this.appliedFilter = true
  },
  methods: {
    ...mapActions({
      updateVacancyCandidacyState: "vacancy/updateVacancyCandidacy",
    }),
    applyCandidateFilter(filter) {
      this.filter = {
        ...this.filter,
        areasInterest: filter.areasInterest,
        otherSkills: filter.otherSkills,
        province: filter.province? `${filter.province}`: filter.province,
        formation: filter.formation,
        languages: filter.languages,
        maxYear: filter.maxYear,
        minYear: filter.minYear,
        maxSalary: filter.maxSalary,
        minSalary: filter.minSalary,
        professionalExperience: filter.professionalExperience,
      };
      this.applyFilter();
    },
    async applyFilter() {
      this.loadingData = true;
      this.$apollo.queries.candidates.skip = false;
      this.filter.page = 1;
      await this.$apollo.queries.candidates.refetch();
    },
    gotoCandidancy() {
      this.$router.push({
        path: `/dashboard/entity/candidacies`,
      });
    },
    changeVacancy() {
      // const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      // this.filter.vacancyId = this.vacancyId;

      // this.getVacancy();
      // console.log(this.filter.ids);
      this.applyFilter();
    },

    getLanguagePerc(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getLanguageVacancyPerc(candidate, vacancy);
    },

    getExpCandPer(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getExpCandVacancyPer(candidate, vacancy);
    },

    getFormationPerc(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getFormationVacancyPerc(candidate, vacancy);
    },

    getAreaCandPer(candidate) {
      const index = this.vacancies.findIndex((v) => v.id == this.vacancyId);
      let vacancy = this.vacancies[index];
      return this.getAreaCandVacancyPer(candidate, vacancy);
    },
    match(candidate) {
      let value = this.DFormatter(
        this.getAreaCandPer(candidate) +
          this.getLanguagePerc(candidate) +
          this.getFormationPerc(candidate) +
          this.getExpCandPer(candidate)
      );
      return value;
    },
    DFormatter(num) {
      let mtnLocale = Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num);
      return mtnLocale;
    },

    checkedCandidate(id) {
      console.log("candidateId: ", id);
      const index = this.selectedCandidates.findIndex((c) => c.id == id);
      if (index > -1) {
        this.selectedCandidates.splice(index, 1);
      } else {
        const candidate = this.filteredItems.find((c) => c.id == id);
        this.selectedCandidates.push(Object.assign({}, candidate));
      }
    },
    checkSelectAll() {
      if (this.selectAll) {
        for (let index = 0; index < this.filteredItems.length; index++) {
          if (!this.filteredItems[index].blocked) {
            this.selectedCandidates.push(
              Object.assign({}, this.filteredItems[index])
            );
          }
        }
      } else {
        this.selectedCandidates = [];
      }
    },
    fetchMore() {
      this.filter.page += 1;
      this.$apollo.queries.candidates.fetchMore({
        variables: {
          filter: this.filter,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.candidates.candidates = [
            ...this.candidates.candidates,
            ...fetchMoreResult.candidates.candidates,
          ];
          return {
            candidates: {
              candidates: [...this.candidates.candidates],
              totalDocs: fetchMoreResult.candidates.totalDocs,
              totalPages: fetchMoreResult.candidates.totalPages,
              blockeds: fetchMoreResult.candidates.blockeds,
            },
          };
        },
      });
    },
    setVacancy(vacancyId) {
      this.vacancyId = vacancyId;
      this.changeVacancy();
    },
    verifyFilter(filter) {
      let count = 0;
      for (const key in filter) {
        switch (key) {
          case "formation":
            if (filter[key][0].course) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "languages":
            if (filter[key][0].name) {
              count++;
            }
            if (filter[key][0].level) {
              count++;
            }
            break;
          case "limit":
            break;
          case "page":
            break;

          default:
            if (filter[key]) {
              count++;
            }
            break;
        }
      }
      return count > 0;
    },
  },
  mounted() {
    const el = document.getElementById("sensor");
    const elementWatcher = scrollMonitor.create(el);
    elementWatcher.enterViewport(() => {
      if (this.candidates && this.filter.page <= this.candidates.totalPages) {
        this.fetchMore();
      }
    });
  },
};
</script>